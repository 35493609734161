<template>
      <b-row>
        <b-col sm="6" class="p-1" v-if="countries !== [] && countries.length > 0">
          <b-card no-body>
            <b-card-header>
              <b-card-title>
                {{$t('instagram.mainCountries')}}
              </b-card-title>
            </b-card-header>
            <b-card-body>
              <vue-apex-charts type="bar" height="300" :options="countryOptions" :series="[{name: $t('youtube.countries'), data: countriesSeries}]"></vue-apex-charts>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col sm="6" class="p-1" v-if="languages[0] !== false && languages.length > 0">
          <b-card no-body>
            <b-card-header>
              <b-card-title>
                {{$t('youtube.mainIdioms')}}
              </b-card-title>
            </b-card-header>
            <b-card-body>
          <vue-apex-charts type="bar" height="300" :options="languagesOptions" :series="[{name: $t('youtube.idioms'), data: languagesSeries}]"></vue-apex-charts>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col sm="6" class="p-1" v-if="type[0] !== false && type.length > 0">
          <b-card no-body>
            <b-card-header>
              <b-card-title>
                Audiencia por tipo
              </b-card-title>
            </b-card-header>
            <b-card-body>
          <vue-apex-charts type="bar" height="300" :options="typeOptions" :series="[{name: 'Tipo', data: typeSeries}]"></vue-apex-charts>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col sm="6" class="p-1" v-if="races[0] !== false && races.length > 0">
          <b-card no-body>
            <b-card-header>
              <b-card-title>
                Principales etnias
              </b-card-title>
            </b-card-header>
            <b-card-body>
          <vue-apex-charts type="bar" height="300" :options="racesOptions" :series="[{name: 'Etnia', data: racesSeries}]"></vue-apex-charts>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
</template>
<script>
import { BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import utils from '@/libs/utils'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    //BCardText,
    VueApexCharts,
  },
  props: {
    languages: {
      type: Array,
      default: () => [false]
    },
    countries: {
      type: Array,
      default: () => [false]
    },
    races: {
      default: () => [false]
    },
    type: {
      default: () => [false]
    },
  },
  computed: {
    countryOptions() {
      const options = {
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors: '#4be3ac',
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '30%',
            endingShape: 'rounded',
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: this.countries.map(item => { return utils.getCountry(item.title ? item.title : item.id ? item.id : item.code) }),
          show: true,
          labels: {
            formatter: (val) => {
              return `${val}%`
            }
          }
        },
        tooltip: {
          y: {
            formatter: (val) => {
              return `${val}%`
            }
          }
        }
      }
      return options
    },
    countriesSeries() {
      const serie = this.countries.map(item => { return item.prc })
      return serie
    },
    languagesOptions() {
      const cat = this.languages.map(item => {
        if (item.title !== 'Other') return utils.getLanguage(item.title ? item.title === 'zh-Hant' ? 'zh' : item.title : item.code === 'zh-Hant' ? 'zh' : item.code)
        return item.title
      })
      const options = {
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors: '#4be3ac',
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '30%',
            endingShape: 'rounded',
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: cat,
          show: true,
          labels: {
            formatter: (val) => {
              return `${val}%`
            }
          }
        },
        tooltip: {
          y: {
            formatter: (val) => {
              return `${val}%`
            }
          }
        }
      }
      return options
    },
    languagesSeries() {
      const serie = this.languages.map(item => { return item.prc })
      return serie
    },
    typeOptions() {
      const options = {
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors: '#4ba3ec',
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '20%',
            endingShape: 'rounded',
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: Object.keys(this.type),
          show: true,

        },
        yaxis: {
          labels: {
            formatter: (val) => {
              return `${val}%`
            }
          }
        },
        tooltip: {
          y: {
            formatter: (val) => {
              return `${val}%`
            }
          }
        }
      }
      return options
    },
    typeSeries() {
      const serie = []
      let count = 0
      while (count < this.typeOptions.xaxis.categories.length) {
        serie.push(this.type[this.typeOptions.xaxis.categories[count]])
        count++
      }
      return serie
    },
    racesOptions() {
      const options = {
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors: '#4cd3ad',
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '20%',
            endingShape: 'rounded',
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: Object.keys(this.races),
          show: true,
        },
        yaxis: {
          labels: {
            formatter: (val) => {
              return `${val}%`
            }
          }
        },
        tooltip: {
          y: {
            formatter: (val) => {
              return `${val}%`
            }
          }
        }
      }
      return options
    },
    racesSeries() {
      const serie = []
      let count = 0
      while (count < this.racesOptions.xaxis.categories.length) {
        serie.push(this.races[this.racesOptions.xaxis.categories[count]])
        count++
      }
      return serie
    },
  }
}
</script>
